
import { ClientesTable } from './clientes.table';
import { EmpresasTable } from './empresas.table';
import { FornecedoresTable } from './fornecedores.table';
import { GruposProdutoServicoTable } from './gruposprodutoservico.table';
import { ProdutosTable } from './produtos.table';
import { ServicosTable } from './servicos.table';
import { UnidadesTable } from './unidades.table';

export class CadastrosBaseDataContext {
  public static servicos: any = ServicosTable.servicos;
  public static clientes: any = ClientesTable.clientes;
  public static fornecedores: any = FornecedoresTable.fornecedores;
  public static empresas: any = EmpresasTable.empresas;
  public static unidades: any = UnidadesTable.unidades;
  public static produtos: any = ProdutosTable.produtos;
  public static gruposProdutos: any = GruposProdutoServicoTable.gruposProdutos;
}
