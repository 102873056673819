export class ServicosTable {
    public static servicos: any = [
        {
            id: 1,
            hash: 'cf8658ef-ba94-49e4-8606-9af147a9e7d0',
            codigo: 'IMPLANTACAO',
            descricao: 'IMPLANTAÇÃO DE SOFTWARE',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 2,
            hash: 'ed40220d-a898-4918-9181-87a3355ff8ff',
            codigo: 'MANUTENCAO_MENSAL',
            descricao: 'MANUTENÇÃO MENSAL',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 3,
            hash: '91ef70cb-37ce-4f16-8066-8fb76e170882',
            codigo: 'SUPORTE',
            descricao: 'SUPORTE DE SISTEMAS',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 4,
            hash: 'e3c4fbd6-5f94-46f1-8fd9-f5e290ad03de',
            codigo: 'INSTALACAO',
            descricao: 'INSTALAÇÃO DE SISTEMA',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 5,
            hash: '18c1ac72-455b-47d5-9007-216d7f3dde14',
            codigo: 'CONSTRUCAO',
            descricao: 'CONSTRUÇÃO CIVIL',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 6,
            hash: '0f8036d4-b132-4900-8257-a2483142f303',
            codigo: 'ACADEMIA',
            descricao: 'SERVIÇOS DE ACADEMIA',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 7,
            hash: 'fcee2ab0-8d47-44c3-9795-37f99eff5c99',
            codigo: 'ABASTECIMENTO',
            descricao: 'ABASTECIMENTO COM ETANOL',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 8,
            hash: '53e7121c-ec7e-4091-a16d-33fe0e4ac4a0',
            codigo: 'AULA',
            descricao: 'AULA PARTICULAR',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 9,
            hash: '3956bdfc-f62d-4320-8ffa-8d20078d5359',
            codigo: 'REITORIA',
            descricao: 'REITORIA NA FACULDADE',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 10,
            hash: 'b1a407d2-c455-40d6-8266-319840dbf7fb',
            codigo: 'ENSINO',
            descricao: 'ENSINO PARTICULAR',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 11,
            hash: '10c383bf-1d48-4d23-8d18-980f39ae3c99',
            codigo: 'PROGRAMACAO',
            descricao: 'PROGRAMACAO DE SOFTWARE',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 12,
            hash: 'baf41f81-3c8e-45fb-ba33-deed1c5d5321',
            codigo: 'CONTABILIDADE',
            descricao: 'SERVIÇOS CONTÁBEIS',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 13,
            hash: '2300c3f2-ba90-4c92-927a-18657c2e9ab1',
            codigo: 'TREINAMENTO',
            descricao: 'SERVIÇOS DE TREINAMENTO',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 14,
            hash: '39e34557-093b-494e-8d67-f41cf5ef4ec6',
            codigo: 'MUSCULACAO',
            descricao: 'SERVIÇOS DE MUSCULAÇÃO',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        },
        {
            id: 15,
            hash: 'a89c33bf-7659-4589-a2c0-19a9d1d71d58',
            codigo: 'LUTA',
            descricao: 'SERVIÇOS DE LUTA LIVRE',
            grupoprodutoservico: '',
            valor: 0,
            alteracaovalor: false,
            unidade: '',
            grupocomissao: ''
        }
    ]
}
