export class UsuariosTable {
    public static usuarios: any = [
      {
        id: 1,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Roberval de souza',
        email: 'roberval@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 2,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Frederico matias',
        email: 'frederico@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 3,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Vanderson Guidi',
        email: 'vanderson@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 4,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Abelardo Barbosa',
        email: 'abelardo@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 5,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Ronaldo Nazário',
        email: 'ronaldo@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 6,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Eduardo Campelo',
        email: 'eduardo@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 7,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Roberto Carlos',
        email: 'roberto@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 8,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Belchior da Silva',
        email: 'belchior@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 9,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Fernando Reis',
        email: 'fernando@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 10,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Edson Santana',
        email: 'edson@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 11,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Carlos Almeida',
        email: 'carlos@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 12,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Luis Fernando Dias',
        email: 'luisfernando@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 13,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Saulo Souza',
        email: 'saulo@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 14,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Pedro Pedreira',
        email: 'pedropedreira@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
      {
        id: 15,
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        nome: 'Frederico Perloso',
        email: 'fredericoperloso@guidis.com.br',
        perfis: ['ADMINISTRADOR', 'GERENCIAL']
      },
    ];
  }
