export class PerfisAcessoTable {
    public static perfisAcesso: any = [
      {
        id: 1,
        codigo: 'ADMINISTRADOR',
        hash: 'c9bc1433-3f9e-4850-8092-c9c58d14a64e',
        descricao: 'ADMINISTRADOR'
      },
      {
        id: 2,
        codigo: 'GERENCIAL',
        hash: '0c6251f5-6259-4f6b-b27d-92c78c78beb9',
        descricao: 'GERENCIAL'
      },
      {
        id: 3,
        codigo: 'MARKETING',
        hash: '23e47e8d-b3b9-4bd0-8fb7-136c8216f9bc',
        descricao: 'MARKETING'
      },
      {
        id: 4,
        codigo: 'FINANCEIRO',
        hash: '7e253283-7f7a-486c-8da8-e2502296a792',
        descricao: 'FINANCEIRO'
      },
      {
        id: 5,
        codigo: 'VENDAS',
        hash: '33ba4c94-55b5-4da7-9a94-cad3b8ff7687',
        descricao: 'VENDAS'
      },
      {
        id: 6,
        codigo: 'SUPERVISOR',
        hash: '7c465e26-00ea-460a-a38d-29dcc5db8992',
        descricao: 'SUPERVISOR'
      },
      {
        id: 7,
        codigo: 'VENDEDOR',
        hash: '1c9eacd8-5db4-4cbc-91c6-7ee6551351d0',
        descricao: 'VENDEDOR'
      },
      {
        id: 8,
        codigo: 'OPERADOR DE CAIXA',
        hash: '23d3fb81-bdb2-4a4f-a7f7-b5e37fe574ae',
        descricao: 'VENDEDOR'
      },
      {
        id: 9,
        codigo: 'COMPRAS',
        hash: '41e58013-ad01-462d-9019-1874541fee23',
        descricao: 'COMPRAS'
      },
      {
        id: 10,
        codigo: 'FISCAL',
        hash: '96fe0f5c-05e8-4e66-aee4-a1a43d73f92f',
        descricao: 'FISCAL'
      },
      {
        id: 11,
        codigo: 'CONTABIL',
        hash: '14c5d486-36d2-424f-b8e1-56762413977f',
        descricao: 'CONTÁBIL'
      },
      {
        id: 12,
        codigo: 'ESTOQUE',
        hash: '7374113e-b0d1-4e97-9f4f-e729cf9dcd8b',
        descricao: 'ESTOQUE'
      },
      {
        id: 13,
        codigo: 'SPED',
        hash: 'bc3c747b-343f-4adb-878c-1bd7578b9649',
        descricao: 'SPED'
      },
      {
        id: 14,
        codigo: 'CONTROLLER',
        hash: '85cac486-3dc7-4328-a400-b31bcd70d396',
        descricao: 'CONTROLLER'
      },
      {
        id: 15,
        codigo: 'SUPORTE',
        hash: 'f14b3bac-aec9-4db0-abeb-68ec575b6f75',
        descricao: 'CONTROLLER'
      },
    ];
  }
